import React, { useEffect, useState } from "react"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"
import { ButtonAction, ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import { CompteWally } from "../Types/Class/CompteWally"
import { SiteMarqueWally } from "../Types/Class/SiteMarqueWally"
import { OrigineWally } from "../Types/Class/OrigineWally"
import { Site } from "../../../../types/Site"
import Select from "react-select"
import { Origine } from "../../../../types/Origine"
import { OrigineMarqueWally } from "../Types/Class/OrigineMarqueWally"
import { MarqueWally } from "../Types/Class/MarqueWally"
import { SiteWally } from "../Types/Class/SiteWally"
import HeaderPageWally from "./HeaderPageWally"

interface Props
{
    contentHeader?: () => JSX.Element

    title: string,
    crm_title: string,
    crm_name: string,
    backLink: string | null,
    toggleReload: boolean,
    toggleAddItem: boolean,

    // Account
    accounts: CompteWally[],
    accountSelected: CompteWally | null,
    loadAccount: boolean,

    // Wally Merged Data
    wallyMerged_urlToFetchData: (account_id:string) => string
    wallyMerged_urlToSaveData: (account_id:string) => string
    wallyMerged_urlToRefreshData: (account_id:string) => string
    wallyMerged_transformedData: (data:any, oth_data:any) => SiteMarqueWally[]|OrigineMarqueWally[]
    wallyMerged_errorMsgFetch: string

    // Wally Data
    wally_columnTitle: string
    wally_urlToFetchData: (account_id:string) => string
    wally_transformedData: (data:any) => SiteWally[]|OrigineWally[]
    wally_errorMsgFetch: string
    wally_select_placeHolder: string

    // Wally Data
    marques_columnTitle: string
    marques_urlToFetchData: (account_id:string) => string
    marques_transformedData: (data:any) => MarqueWally[]
    marques_errorMsgFetch: string
    marques_select_placeHolder: string

    // ESeller Data
    eSeller_columnTitle: string
    eSeller_urlToFetchData: string
    eSeller_errorMsgFetch: string
    eSeller_transformedData: (data:any) => []
    eSeller_select_placeHolder: string
}

interface selectValue
{
    label: string,
    value: number,
}


interface FormAdd
{
    formIdMarque?: number | null,
    formIdWally?: number | null,
    formIdESeller?: number | null,
}
export default function Mapping(props:Props) {
    const backofficeApiClient = new BackOfficeAPIClient()

    // Manage DOM
    const [ isSetting, setIsSetting ] = useState<boolean>(false)
    const [ isCurrentSetting, setIsCurrentSetting ] = useState<boolean>(false)
    const [ fetchProgress, setFetchProgress ] = useState<boolean>(false)

    // Account data
    const [ accounts, setAccounts ] = useState<CompteWally[]>(props.accounts)
    const [ accountSelected, setAccountSelect ] = useState<CompteWally | null>(null)


    // Wally Data
    const [ wallyMergedData, setWallyMergedData ] = useState<Array<OrigineMarqueWally> | Array<SiteMarqueWally> >([])

    const [ wallyData, setWallyData ] = useState<Array<OrigineWally> | Array<SiteWally> >([])
    const [ marquesData, setMarquesData ] = useState<MarqueWally[]>([])

    // ESeller data
    const [ eSellerData, setESellerData ] = useState<Site[]|Origine[]>([])

    //  FORM
    const [ formAdd, setFormAdd] = useState<FormAdd>({
        formIdMarque: null,
        formIdWally: null,
        formIdESeller: null,
    })

    // Other
    const [infoAction, setInfoAction] = useState<any>({
        className:null,
        message: "",
        loader: false
    })

    useEffect(() => {
        setFetchProgress(true)
        if(props.loadAccount) fetchAccount();
        fetchESellerData()
            .then(eSellerData => {
                if(props.accountSelected) {
                    reloadAccountData(props.accountSelected.IdWallyCompte, eSellerData)
                    setAccountSelect(props.accountSelected)
                }
            }).finally(    () => setFetchProgress(false))
    }, [])

    // Account
    const fetchAccount = () => {
        setFetchProgress(true)
        backofficeApiClient
            .get<CompteWally[]>('/interfaces/wally/'+props.crm_name+'/accounts')
            .then((accountData: CompteWally[]) => {
                if (accountData.length === 0) return
                const transformedAccounts = accountData.map(account => new CompteWally(account))
                setAccounts(transformedAccounts)
            }).catch((err) => {
            console.error('Erreur lors de la récupération des comptes :', err)
        }).finally(() => setFetchProgress(false))
    }

    const reloadAccountData = (account_id: string, eSellerDataTmp?:[]) => {
        fetchMarquesData(account_id);
        fetchWallyData(account_id)
        fetchWallyMergedData(account_id, eSellerDataTmp);
    }

    const handleSelectAccount = (e: any) => {
        const accSel = accounts.find(acc => acc.IdWallyCompte == e.target.value)
        if (!accSel) {
            setAccountSelect(null)
            setWallyMergedData([]);
            return
        }
        if (isCurrentSetting) { // Si en cours de modif alors on met une alert
            if (!window.confirm("Vous avez des modifications en cours. Annuler ? ")) return
        }
        setAccountSelect(accSel)
        setIsCurrentSetting(false)
        handleFetchReloadData(accSel)
        //reloadAccountData(accSel.IdWallyCompte)
    }

    // Wally
    const fetchWallyMergedData = (account_id:string, wds?:[]) => {
        setFetchProgress(true)
        backofficeApiClient
            .get<[]>(props.wallyMerged_urlToFetchData(account_id))
            .then((r: []) => {
                if (r.length === 0) {
                    setWallyMergedData([])
                    return
                }
                if(eSellerData.length === 0 && (wds && wds.length > 0)){
                    setWallyMergedData(props.wallyMerged_transformedData(r, wds))
                } else {
                    const newWallyMergedData = props.wallyMerged_transformedData(r, eSellerData);
                    const dataSet = newWallyMergedData.map((nwmd) => {
                        const findIndex = wallyMergedData
                            .findIndex((wmd) => wmd.getPrimaryKey() === nwmd.getPrimaryKey());
                        if(findIndex === -1 ) return nwmd;
                        const findOld = wallyMergedData[findIndex] as SiteMarqueWally|OrigineMarqueWally;
                        nwmd.setIdModifying(findOld.getIdModifying());
                        return nwmd;
                    }) as SiteMarqueWally[]|OrigineMarqueWally[];
                    setWallyMergedData(dataSet);
                }
            }).catch((err) => {
            console.error('Erreur lors de la récupération des points de vente:', err)
        }).finally(() => setFetchProgress(false))
    }

    // Marques
    const fetchMarquesData = (account_id:string) => {
        backofficeApiClient
            .get<[]>(props.marques_urlToFetchData(account_id))
            .then((data: []) => {
                if (data.length === 0) {
                    setMarquesData([])
                    return
                }
                setMarquesData(props.marques_transformedData(data))
            }).catch((err) => {
            console.error('Erreur lors de la récupération les '+props.eSeller_errorMsgFetch+' eSeller:', err)
        })
    }

    // ESeller
    const fetchWallyData = (account_id:string) => {
        setFetchProgress(true)
        backofficeApiClient
            .get<[]>(props.wally_urlToFetchData(account_id))
            .then((data: []) => {
                if (data.length === 0) {
                    setWallyData([])
                    return
                }
                setWallyData(props.wally_transformedData(data))
            }).catch((err) => {
            console.error('Erreur lors de la récupération les '+props.eSeller_errorMsgFetch+' eSeller:', err)
        }).finally(() => setFetchProgress(false))
    }

    // ESeller
    const fetchESellerData = async () => {
        try{
            const data = await backofficeApiClient
                .get<[]>(props.eSeller_urlToFetchData)
            if (data.length === 0) {
                setESellerData([])
                return
            }
            setESellerData(props.eSeller_transformedData(data))
            return await props.eSeller_transformedData(data);
        } catch(err){
            console.error('Erreur lors de la récupération les '+props.eSeller_errorMsgFetch+' eSeller:', err)
        } finally{
            setFetchProgress(false)
        }
        setFetchProgress(true)

    }


    // ACTION
    const handleOnChangeEsellerAction = (wd:SiteMarqueWally|OrigineMarqueWally) => {
        return (selected:selectValue) => {
            if (!selected) {
                alert("Veuillez sélectionner une origine ou annulez la modification")
                return
            }
            wd.setIdModifying(selected.value);
            const updatedData: any = {...wd, isCurrentChange: true}
            setWallyMergedData((prev: any ) =>
                prev.map((p:SiteMarqueWally|OrigineMarqueWally) =>  p.getPrimaryKey() === wd.getPrimaryKey() ? updatedData : p),
            )
        }
    }

    const handleChangeData = (wd:SiteMarqueWally|OrigineMarqueWally) => {
        return () => {
            const updatedWd = { ...wd,  isCurrentChange: true, }
            setWallyMergedData((prev: any) =>
                prev.map((p:any) =>
                    p.getPrimaryKey() === updatedWd.getPrimaryKey() ? updatedWd : p
                ),
            )

        }
    }

    const handleCancelChange = (wd:SiteMarqueWally|OrigineMarqueWally) => {
        return () => {
            wd.resetIdModifying()
            const updatedWd = { ...wd,  isCurrentChange: false }
            setWallyMergedData((prev: any) =>
                prev.map((p:any) => p.getPrimaryKey() === wd.getPrimaryKey() ? updatedWd : p ),
            )
        }
    }

    // SAVE DATA
    const handleFetchSaveData = (savedData: SiteMarqueWally|OrigineMarqueWally) => {
        return () => {
            if (!accountSelected) return
            backofficeApiClient
                .put<any>(savedData.getUrlToSave(accountSelected.IdWallyCompte), savedData.getDataToSave())
                .then((r: any) => {
                    fetchWallyMergedData(accountSelected.IdWallyCompte)
                })
                .catch((err) => {
                    const err_resp = JSON.parse(err.message)
                    if (err_resp.error_code === 409) alert(err_resp.message)
                    console.error('Erreur lors de la modification de '+props.eSeller_errorMsgFetch+' eSeller:', err)
                })
        }
    }


    // DELETE DATA

    const handleDeletingData = (wd: OrigineMarqueWally|SiteMarqueWally) => {
        return () => {
            if (!accountSelected) return
            backofficeApiClient
                .delete<any>(wd.getUrlToDelete(accountSelected.IdWallyCompte), null)
                .then((r: any) => {
                    fetchWallyMergedData(accountSelected.IdWallyCompte)
                })
                .catch((err) => {
                    console.error('Erreur lors de la suppression de '+props.wally_errorMsgFetch+' :', err)
                })
        }
    }

    // FORM ADD

    const handleOnChangeMarqueIdForm = (value: selectValue) => {
        setFormAdd((prev) => ({ ...prev, formIdMarque: value ? value.value : null }))
    }
    const handleOnChangeESellerIdForm = (value: selectValue) => {
        setFormAdd((prev) => ({ ...prev, formIdESeller: value ? value.value : null }))
    }
    const handleOnChangeWallyIdForm = (value: selectValue) => {
        setFormAdd((prev) => ({ ...prev, formIdWally: value ? value.value : null }))
    }

    const handleFetchFormAdd = () => {
        if (!accountSelected) return
        backofficeApiClient
            .post<any>(props.wallyMerged_urlToSaveData(accountSelected.IdWallyCompte), formAdd)
            .then((r: any) => {
                fetchWallyMergedData(accountSelected.IdWallyCompte)
                setFormAdd({ formIdWally: null, formIdESeller: null, formIdMarque: null })
            })
            .catch((err) => {
                const err_resp = JSON.parse(err.message)
                if (err_resp.error_code === 409) alert(err_resp.message)
                console.error('Erreur lors de l\'enregistrement du formulaire:', err)
            })
        //.finally(() => setIsOnSaving(false))
    }

    // ACTUALISATION
    const handleFetchReloadData = (account:CompteWally|null) => {
        if(!account) return

        setInfoAction({...infoAction, loader : true})
        backofficeApiClient
            .post<any>(props.wallyMerged_urlToRefreshData(account.IdWallyCompte),null)
            .then((r: any) => {
                setInfoAction({message:r.message, className:"alert-success", loader:false})
                reloadAccountData(account.IdWallyCompte);
            })
            .catch((err) => {
                const err_resp = JSON.parse(err.message)
                if (err_resp.error_code === 409) {
                    setInfoAction({message:err_resp.message, className:"alert-warning", loader:false})
                    alert(err_resp.message)
                    return;
                }
                console.error('Erreur lors de l\'actualisation des sites/marques:', err);
                setInfoAction({message:"Erreur lors de l\'actualisation des sites/marques : "+err.message, className:"alert-danger", loader:false})
            })
    }

    return <div className={"container-fluid mb-5 pr-5 pl-5"}>
        <div className={"row"}>
            {props.backLink !== null &&
                <div>
                    <ButtonAction href={props.backLink} className={"btn btn-secondary"}>
                        <i className={"fa-solid fa-chevron-left fa-xs"}></i>
                    </ButtonAction>
                </div>
            }
            <div className={"col"}>
                <HeaderPageWally title={props.title} />
            </div>
        </div>

        {props.contentHeader && <>{props.contentHeader()}</>}
        <div className={"row mb-4"}>
            <div className={"col"}>

                <div className="btn-group" role="group" aria-label="Button group with nested dropdown">
                    <div>
                        {props.toggleReload && accountSelected &&
                            <ButtonAction2 onClick={() => handleFetchReloadData(accountSelected)} type="button" className="btn bg-info">
                                <i className={"fa-solid fa-rotate fa-xs"}></i>
                                <span className={"pl-2"}>Actualiser</span>
                            </ButtonAction2>
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className={"row mt-3"}>
            <div className={"col"}>
                {infoAction.className &&
                    <div className={"alert "+ infoAction.className}>{infoAction.message}</div>
                }
                <div className={"text-center"}>
                    {(fetchProgress || infoAction.loader) && <span><i className="fa fa-spinner fa-spin fa-fw fa-xl"></i>&nbsp;</span>}
                </div>
                <div className={"col bg-secondary p-1"}>
                    {accounts.length > 0 &&
                        <select value={accountSelected?.IdWallyCompte} onChange={handleSelectAccount} name="IDDRProfileEsellerLead"
                                className="form-control form-control-sm col-3">
                            <option value="0">Sélectionner un compte</option>
                            {accounts.map((account) => {
                                return <option key={account.IdWallyCompte} value={account.IdWallyCompte}>{account.Identifiant + " " + account.EmailNotification}</option>
                            })}
                        </select>
                    }
                </div>

                <table className={"table table-bordered"}>
                    <thead>
                    <tr>
                        <th className={"w-25"}> {props.wally_columnTitle}</th>
                        <th className={"w-50"}>{props.marques_columnTitle}</th>
                        <th className={"w-50"}>{props.eSeller_columnTitle}</th>
                        <th className={"w-auto"}>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.toggleAddItem &&
                        <tr className={"thead"} key={"new"} >
                            <td>
                                <Select name="formIdWally"
                                        value={formAdd.formIdWally}
                                        options={wallyData.map((wd) => {
                                            return { value: wd.getPrimaryKey(), label: wd.getTitle() }
                                        })}
                                        isSearchable={true} required={false}
                                        placeholder={"Sélectionnez " + props.wally_select_placeHolder + " du CRM"}
                                        onChange={handleOnChangeWallyIdForm}
                                        isMulti={false} isClearable={true}
                                />
                            </td>
                            <td>
                                <Select name="idMarqueExternal"
                                        value={formAdd.formIdMarque}
                                        options={marquesData.map((m) => {
                                            return { value: m.getPrimaryKey(), label: m.getTitle() }
                                        })}
                                        isSearchable={true} required={false}
                                        placeholder={"Sélectionnez " + props.marques_select_placeHolder + " du CRM"}
                                        onChange={handleOnChangeMarqueIdForm}
                                        isMulti={false} isClearable={true}
                                />

                            </td>
                            <td>
                                <Select name="idOrigineSeller"
                                        value={formAdd.formIdESeller}
                                        options={eSellerData.map((o) => {
                                            return { value: o.getValue(), label: o.getLabel() }
                                        })}
                                        isSearchable={true} required={false}
                                        placeholder={"Sélectionnez " + props.eSeller_select_placeHolder}
                                        onChange={handleOnChangeESellerIdForm}
                                        isMulti={false} isClearable={true}
                                />
                            </td>
                            <td className={"d-inline-flex justify-content-center col"}>
                                <ButtonAction2 onClick={handleFetchFormAdd} type="button"
                                               disabled={(
                                                   formAdd.formIdESeller === null ||
                                                   formAdd.formIdMarque === null ||
                                                   formAdd.formIdWally === null)}
                                               className="btn btn-secondary bg-success ">
                                    <i className={"fa-solid fa-plus fa-xs"}></i>
                                </ButtonAction2>
                            </td>
                        </tr>
                    }
                    {wallyMergedData.map((wd: SiteMarqueWally | OrigineMarqueWally) =>
                        <tr key={wd.getPrimaryKey()}>
                            <td>
                                {wd.getTitleWally()}
                            </td>
                            <td>
                                {wd.getTitleMarque()}
                            </td>
                            <td>
                                <>
                                    {wd.isCurrentChange ?
                                        <Select name="idESeller"
                                                value={wd.getIdModifying()}
                                                options={eSellerData.map((ed) => {
                                                    return { value: ed.getValue(), label: ed.getLabel() }
                                                })}
                                                isSearchable={true} required={false}
                                                placeholder={props.eSeller_select_placeHolder}
                                                onChange={handleOnChangeEsellerAction(wd)}
                                                isMulti={false} isClearable={false}
                                        />
                                        :
                                        <>  {(wd.getIdModifying() !== null) ? wd.getTitleESeller() : "-"}   </>
                                    }
                                </>
                            </td>
                            <td>
                                <div className={"d-inline-flex"}>
                                    {(wd.getESellerId() !== null) ?
                                        <>
                                            {wd.isCurrentChange ?
                                                <>
                                                    <ButtonAction2 onClick={handleFetchSaveData(wd)}
                                                                   className="btn btn-success btn-sm mr-2">
                                                        <i className={"fa-solid fa-check fa-xs"}></i>
                                                    </ButtonAction2>
                                                    <ButtonAction2 onClick={handleCancelChange(wd)}
                                                                   className="btn btn-secondary btn-sm mr-2">
                                                        <i className={"fa-solid fa-ban fa-xs"}></i>
                                                    </ButtonAction2>
                                                </>
                                                :
                                                <>
                                                    <ButtonAction2 onClick={handleChangeData(wd)} className="btn btn-primary btn-sm mr-2">
                                                        <i className={"fa-solid fa-pen fa-xs"}></i>
                                                    </ButtonAction2>
                                                    <ButtonAction2 onClick={handleDeletingData(wd)}
                                                                   className="btn btn-secondary btn-danger btn-sm">
                                                        <i className={"fa-solid fa-trash fa-xs"}></i>
                                                    </ButtonAction2>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <ButtonAction2 onClick={handleFetchSaveData(wd)}
                                                           className="btn btn-success btn-sm mr-2">
                                                <i className={"fa-solid fa-check fa-xs"}></i>
                                            </ButtonAction2>

                                        </>
                                    }


                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>

    </div>

}
