import { MarqueWally } from "./MarqueWally"
import { OrigineWally } from "./OrigineWally"
import { Origine } from "../../../../../types/Origine"

export class OrigineMarqueWally
{
    IDOrigineAction!: number
    IdWallyMarque!: number
    NomMarque!:string
    NomOrigine!:string
    IdWallyOrigine!: number
    marque?: MarqueWally
    origine?: OrigineWally
    origineESBO?: Origine
    isCurrentChange?: boolean
    IDOrigineActionModifying?: number | null

    constructor(data?: Partial<OrigineMarqueWally>, originesESBO?: Origine[], marques?: MarqueWally[], origines?: OrigineWally[]) {
        Object.assign(this, data)
        if (this.IdWallyMarque && marques) {
            this.marque = marques.find(m => m.IdWallyMarque === this.IdWallyMarque)
        }
        if (this.IdWallyOrigine && origines) {
            this.origine = origines.find(o => o.IdWallyOrigine === this.IdWallyOrigine)
        }
        //console.log(originesESBO,"originesESBO")
        if (this.IDOrigineAction && originesESBO) {
            this.origineESBO = originesESBO.find(oes => oes.IDOrigineAction === this.IDOrigineAction)
        }
        //console.log( this.origineESBO)
        this.isCurrentChange = this.IDOrigineAction === null;
        this.IDOrigineActionModifying = this.IDOrigineAction
    }

    getPrimaryKey = () => {
        return this.IdWallyOrigine + "_" + this.IdWallyMarque
    }

    getESellerId = () => this.IDOrigineAction


    getTitleWally = () => {
        return this.NomOrigine
    }
    getTitleMarque = () => {
        return this.NomMarque
    }

    getTitleESeller = () => {
        return this.origineESBO?.LibelleFamille + " - " + this.origineESBO?.LibelleSousFamille + " - " + this.origineESBO?.LibelleOrigine
    }
    getIdModifying = () => {
        return this.IDOrigineActionModifying
    }
    setIdModifying = (id:number|null|undefined) => {
        return this.IDOrigineActionModifying = id;
    }
    resetIdModifying = () => {
        return this.IDOrigineActionModifying = this.IDOrigineAction;
    }

    getUrlToSave = (account_id:string) =>
         '/interfaces/wally/accounts/' + account_id + "/marques-origines/" + this.IdWallyOrigine + "/" + this.IdWallyMarque

    getUrlToDelete = (account_id:string) =>
         '/interfaces/wally/accounts/' + account_id + "/marques-origines/" + this.IdWallyOrigine + "/" + this.IdWallyMarque


    getDataToSave = () => {
        return { idOrigineAction: this.getIdModifying() }
    }
}

