import React, { Component } from 'react';
import Utils from '../../Utils';

var lodash = require('lodash')

class Form extends Component {

	constructor(props) {
		super(props);

		this.state = {
			utilisateur: null,
			profils: [],
			profilsLead: [],
			profilsEDataVoice: [],
			sites: [],
			fetchInProgress: false,
			fetchUtilisateur: false,
			configESellerLead: null,
			redirect: false,
			initial: {
				lastname: '',
				firstname: '',
				subname: ''
			},
			configRenaultCSB: {
				IDPeople: "",
				Username: "",
				Password: "",
				config_exist: false
			},
			updated: []
		}

		this.submit = {
			"utilisateur": this.submitGeneral.bind(this),
			"renaultcsb": this.submitConfigRenaultCSB.bind(this)
		}
	}

	componentWillMount() {

		if (this.props.match.params.id) {
			this.fetchUtilisateur()
			this.fetchSites()
			if (!Utils.isAccesClient()) {
				this.fetchConfigRenaultCSB()
			}
		}
		else {
			this.setState({
				utilisateur: {
					"drprofile_id": '',
					"Name": '',
					"Lastname": '',
					"Firstname": '',
					"Initial": '',
					"LoginWEB": '',
					"Password": '',
					"Actif": true,
					"Superviseur": false,
					"AccesCRMeSeller": false,
					"EMail": '',
					"PhoneNumber": '',
					"MobilePhone": '',
					"PrivatePhone": '',
					"IsAccountOpened": false,
					"OffreEnTTC": true,
					"HorsTraitementLeads": false,
					"RechercheVNParFiltre": false,
					"RechercheAvanceeVNParDefaut": false,
					"TypeVN": false,
					"TypeVO": false,
					"TypeVDL": false,
					"TypeConseiller": false,
					"TypeAutre": false,
					"EnCopieRDVeSellerLead": false,
					"AccesLeadAPV": false,
					"AccesLeadVNVO": false,
					"CodeIPNRenault": "",
					"IDProfil": "",
				}
			})
		}

		this.fetchProfils()
		this.fetchProfilLead()
		this.fetchProfilsEDataVoice()
		this.fetchConfigESellerLead()
	}

	componentDidUpdate() {
		if (this.props.match.params.id && !this.state.fetchUtilisateur) {
			this.fetchUtilisateur()
			this.fetchSites()
		}
	}

	componentDidMount() {
		this.generateTooltips();
	}

	fetchUtilisateur() {

		this.setState({
			fetchUtilisateur: true,
		})

		fetch(localStorage.backoffice_api + '/api/utilisateurs/' + this.props.match.params.id, {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				if (json.EstEDVMarketing && Utils.isAccesClient()) {
					this.props.history.push('/utilisateurs')
					return
				}
				this.setState({
					utilisateur: json,
				}, () => this.generateTooltips());
			})
			.catch((error) => {
				console.log(error)
			});
	}

	fetchConfigRenaultCSB() {
		fetch(localStorage.backoffice_api + '/api/interfaces/renault/csb/utilisateur/' + this.props.match.params.id, {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					configRenaultCSB: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	fetchProfilLead() {
		fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam/types', {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					profilsLead: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	async fetchProfilsEDataVoice() {
		try {
			const response = await fetch(localStorage.backoffice_api + '/api/profils/eDataVoicePrc', {
				method: 'GET',
				headers: {
					authorization: localStorage.JWT,
				},
			})
			var profils = lodash.sortBy(await response.json(), 'Libelle')

			this.setState({
				profilsEDataVoice: profils,
			})
		}
		catch (error) {
			console.log(error)
		}
		finally {
			this.setState({
				fetching: false
			})
		}
	}

	fetchSites() {
		fetch(localStorage.backoffice_api + '/api/utilisateurs/' + this.props.match.params.id + '/sites', {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				let sort = lodash.sortBy(json, ['Descr', 'Plaque_Libelle'])
				this.setState({
					sites: sort,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	fetchProfils() {
		fetch(localStorage.backoffice_api + '/api/profils/eSellerTeam', {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					profils: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	fetchConfigESellerLead() {
		fetch(localStorage.backoffice_api + '/api/interfaces/esellerLead/config', {
			method: 'GET',
			headers: {
				authorization: localStorage.JWT,
			}
		})
			.then((response) => {
				if (!response.ok) {
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					configESellerLead: json,
				});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	onChange(event) {
		var type = event.target.type
		var name = event.target.name
		var value = event.target.value

		var utilisateur = this.state.utilisateur
		var initial = this.state.initial

		if (type === 'checkbox') {
			utilisateur[name] = event.target.checked
		}
		else {
			utilisateur[name] = value
		}

		if (name == "Initial") {
			if (value.length == 0) {
				initial.firstname = ''
				initial.subname = ''
				initial.lastname = ''
			}
			if (value.length == 1) {
				initial.firstname = value[0]
				initial.subname = ''
				initial.lastname = ''
			}
			if (value.length == 2) {
				initial.firstname = value[0]
				initial.subname = ''
				initial.lastname = value[1]
			}
			if (value.length == 3) {
				initial.firstname = value[0]
				initial.subname = value[1]
				initial.lastname = value[2]
			}
		}

		if (name == 'Lastname') {
			if (value == "") {
				initial.lastname = ''
			} else {
				initial.lastname = value[0].toUpperCase()
				if (utilisateur.Firstname != "") {
					initial.firstname = utilisateur.Firstname[0].toUpperCase()
				}
				initial.subname = ''
			}
		}

		if (name == 'Firstname') {
			if (value == "") {
				initial.firstname = ''
			} else {
				initial.firstname = value[0].toUpperCase()
				if (utilisateur.Lastname != "") {
					initial.lastname = utilisateur.Lastname[0].toUpperCase()
				}
				initial.subname = ''
			}
		}

		if (name == 'Firstname' || name == 'Lastname' || name == "Initial") {
			utilisateur['Initial'] = initial.firstname + initial.subname + initial.lastname
		}

		this.setState({
			updated: this.addUpdated("utilisateur"),
			utilisateur: utilisateur,
			initial: initial
		})
	}

	onChangeRenaultCSB(event) {
		this.setState({
			configRenaultCSB: { ...this.state.configRenaultCSB, [event.target.name]: event.target.value },
			updated: this.addUpdated("renaultcsb")
		})
	}

	onChangeSite(IDSite, event) {
		var sites = this.state.sites

		var index = lodash.findIndex(sites, (s) => {
			return parseInt(s.IDSite, 10) === parseInt(IDSite, 10)
		})

		sites[index].checked = event.target.checked

		this.setState({
			updated: this.addUpdated("utilisateur"),
			sites: sites,
		})
	}

	addUpdated(updatedEntity) {
		let updated = this.state.updated
		if (!updated.includes(updatedEntity)) {
			updated.push(updatedEntity)
		}
		return updated
	}

	async handleSubmit(event) {
		event.preventDefault()

		this.setState({
			fetchInProgress: true,
		})

		for (let i = 0; i < this.state.updated.length; i++) {
			await this.submit[this.state.updated[i]]()
		}

		if (this.props.match.params.id) {
			if (this.state.redirect == true) {
				this.props.history.push('/utilisateurs/')
			} else {
				this.fetchUtilisateur()
				this.fetchSites()
				if (!Utils.isAccesClient()) {
					this.fetchConfigRenaultCSB()
				}
			}

		}

		this.setState({
			fetchInProgress: false,
			updated: []
		})

	}

	async submitGeneral() {
		if (!this.props.match.params.id) {
			await this.createUtilisateur();
		}
		else {
			await this.editUtilisateur();
		}
	}

	redirectList(choix) {
		this.setState({
			redirect: choix
		})
	}

	generateTooltips() {
		window.$('[data-toggle="tooltip"]').tooltip();
	}

	buttonSave() {
		return (<div className="row justify-content-center">
			{this.state.updated.length > 0 ?
				<div className="row" style={{ marginTop: '15px', marginBottom: '15px', marginLeft: "10px", marginRight: "10px" }}>
					<div className="col text-center">
						<button disabled={this.state.fetchInProgress} type="submit" className="btn btn-primary" onClick={this.redirectList.bind(this, false)}>
							{this.state.fetchInProgress ?
								<span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
								: null}
							Enregistrer
						</button>
					</div>
				</div>
				: null}

			{this.state.updated.length > 0 && !this.state.utilisateur.EstEDVMarketing ?
				<div className="row" style={{ marginTop: '15px', marginBottom: '15px', marginLeft: "10px", marginRight: "10px" }}>
					<div className="col text-center">
						<button disabled={this.state.fetchInProgress} className="btn btn-primary" onClick={this.redirectList.bind(this, true)}>
							{this.state.fetchInProgress ?
								<span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
								: null}
							Enregistrer et revenir a la liste
						</button>
					</div>
				</div>
				: null}
		</div>)
	}

	async createUtilisateur() {
		await fetch(localStorage.backoffice_api + '/api/utilisateurs/', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.utilisateur)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response.statusText);
				}
				return response.json();
			})
			.then((json) => {
				this.setState({
					utilisateur: null,
					updated: [],
					fetchInProgress: false,
				}, () => {
					if (this.state.redirect == true) {
						this.props.history.push('/utilisateurs/')
					} else {
						this.props.history.push('/utilisateurs/' + json.IDPeople)
					}

				})

			})
			.catch((error) => {
				console.log(error)
			});
	}

	async editUtilisateur() {
		await fetch(localStorage.backoffice_api + '/api/utilisateurs/' + this.props.match.params.id, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.utilisateur)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response);
				}
				return response.json();
			})
			.then(async (json) => {
				await fetch(localStorage.backoffice_api + '/api/utilisateurs/' + this.props.match.params.id + '/sites', {
					method: 'PUT',
					headers: {
						"Content-Type": "application/json",
						authorization: localStorage.JWT,
					},
					body: JSON.stringify(this.state.sites)
				})
					.then((response) => {
						if (!response.ok) {
							response.json().then((value) => {
								alert(value.message)
							});
							this.setState({
								fetchInProgress: false,
							})
							throw Error(response);
						}
						this.setState({
							utilisateur: null,
							sites: [],
							fetchInProgress: false,
						})
					})
					.catch((error) => {
						console.log(error)
					});
			})
			.catch((error) => {
				console.log(error)
			});
	}

	hasNissanSitesChecked() {
		var sitesNissan = lodash.filter(this.state.sites, (site) => {
			return site.TPR_IDTypeProduit === 8 && site.checked;
		});

		return sitesNissan.length ? true : false;
	}

	onChangeConfigNissan(IDSite, event) {
		var utilisateur = this.state.utilisateur;

		if (!utilisateur.ConfigNissan) {
			utilisateur.ConfigNissan = []
		}

		var idx = lodash.findIndex(utilisateur.ConfigNissan, { "IDSite": IDSite });
		if (idx === -1) {
			var configNissan = {
				"CodeVendeur": "",
				"VecturyID": "",
				"IDSite": IDSite
			};
			configNissan[event.target.dataset.type] = event.target.value;
			utilisateur.ConfigNissan.push(configNissan);
		}
		else
			utilisateur.ConfigNissan[idx][event.target.dataset.type] = event.target.value;

		this.setState({
			utilisateur: utilisateur,
			updated: this.addUpdated("utilisateur"),
		})
	}

	checkAllSite(event) {
		var sites = this.state.sites

		if (Utils.isAccesClient()) //Accès client on ne coche pas les sites Nissan qui ne l'étaient pes
		{
			for (var site in sites) {
				sites[site].checked = sites[site].TPR_IDTypeProduit !== 8 ? true : sites[site].checked;
			}
		}
		else {
			for (var site in sites) {
				sites[site].checked = true;
			}
		}

		this.setState({
			updated: this.addUpdated("utilisateur"),
			sites: sites,
		})
	}

	uncheckAllSite(event) {
		var sites = this.state.sites

		if (Utils.isAccesClient()) //Accès client on ne décoche pas les sites Nissan qui ne l'étaient pas
		{
			for (var site in sites) {
				sites[site].checked = sites[site].TPR_IDTypeProduit !== 8 ? false : sites[site].checked;
			}
		}
		else {
			for (var site in sites) {
				sites[site].checked = false;
			}
		}

		this.setState({
			updated: this.addUpdated("utilisateur"),
			sites: sites,
		})
	}

	async createConfigRenaultCSB() {
		await fetch(localStorage.backoffice_api + '/api/interfaces/renault/csb/utilisateur', {
			method: 'POST',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.configRenaultCSB)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response.statusText);
				}
				return response.json();
			})
			.catch((error) => {
				console.log(error)
			});
	}

	async updateConfigRenaultCSB() {
		await fetch(localStorage.backoffice_api + '/api/interfaces/renault/csb/utilisateur/' + this.props.match.params.id, {
			method: 'PUT',
			headers: {
				"Content-Type": "application/json",
				authorization: localStorage.JWT,
			},
			body: JSON.stringify(this.state.configRenaultCSB)
		})
			.then((response) => {
				if (!response.ok) {
					response.json().then((value) => {
						alert(value.message)
					});
					this.setState({
						fetchInProgress: false,
					})
					throw Error(response.statusText);
				}
				return response.json();
			})
			.catch((error) => {
				console.log(error)
			});
	}

	async submitConfigRenaultCSB() {

		if (!Utils.isAccesClient()) {
			if (this.state.configRenaultCSB.config_exist) {
				await this.updateConfigRenaultCSB()
			} else {
				if (this.state.configRenaultCSB.Username !== "" && this.state.configRenaultCSB.Password !== "") {
					await this.createConfigRenaultCSB()
				}
			}
		}

	}


	render() {

		if (!this.state.utilisateur) {
			return null;
		}

		if (Utils.isAccesClient()) {
			return this.renderAccesClient();
		}

		return (
			<div className='container-fluid'>

				<h2>Utilisateurs</h2>
				<br />

				<ul className="nav nav-tabs" id="myTab">
					<li className="nav-item">
						<a className="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true">Général</a>
					</li>

					{this.props.match.params.id && <li className="nav-item" key='sites-tab-li'>
						<a className="nav-link" id="renault-tab" data-toggle="tab" href="#renault" role="tab" aria-controls="renault" aria-selected="true">Renault</a>
					</li>}
				</ul>
				<form onSubmit={this.handleSubmit.bind(this)}>
					<div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
						<div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">

							{this.state.utilisateur.EstEDVMarketing &&
								<div className='alert alert-warning'>
									<i className='fa fa-warning mr-1'></i>
									Compte système utilisé par eDataVoice Marketing pour créer ou mettre à jour des informations dans eDataVoice CRM (PRC). Toute modification hasardeuse peut provoquer des problèmes de communication entre les 2 applications. Ne modifier qu'en connaissance de cause.
								</div>
							}

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="Lastname" className="col-sm-3 col-form-label">Nom</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Lastname" name="Lastname" value={this.state.utilisateur.Lastname} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="Firstname" className="col-sm-3 col-form-label">Prénom</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Firstname" name="Firstname" value={this.state.utilisateur.Firstname} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6">
									<div className="form-group row">
										<label htmlFor="Initial" className="col-sm-3 col-form-label">Initiale</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Initial" name="Initial" value={this.state.utilisateur.Initial ? this.state.utilisateur.Initial : ''} onChange={this.onChange.bind(this)} maxLength="3" required='required' />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="EMail" className="col-sm-3 col-form-label">Email</label>
										<div className="col-sm-6">
											<input type="email" className="form-control form-control-sm" id="EMail" name="EMail" value={this.state.utilisateur.EMail ? this.state.utilisateur.EMail : ''} onChange={this.onChange.bind(this)} maxLength="80" />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="PhoneNumber" className="col-sm-3 col-form-label">Téléphone</label>
										<div className="col-sm-6">
											<input type="tel" className="form-control form-control-sm" id="PhoneNumber" name="PhoneNumber" value={this.state.utilisateur.PhoneNumber ? this.state.utilisateur.PhoneNumber : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="MobilePhone" className="col-sm-3 col-form-label">Téléphone mobile</label>
										<div className="col-sm-6">
											<input type="tel" className="form-control form-control-sm" id="MobilePhone" name="MobilePhone" value={this.state.utilisateur.MobilePhone ? this.state.utilisateur.MobilePhone : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="PrivatePhone" className="col-sm-3 col-form-label">Téléphone privé</label>
										<div className="col-sm-6">
											<input type="tel" className="form-control form-control-sm" id="PrivatePhone" name="PrivatePhone" value={this.state.utilisateur.PrivatePhone ? this.state.utilisateur.PrivatePhone : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="LoginWEB" className="col-sm-3 col-form-label">Login</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="LoginWEB" name="LoginWEB" value={this.state.utilisateur.LoginWEB} onChange={this.onChange.bind(this)} maxLength="10" required='required' />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="Password" className="col-sm-3 col-form-label">Mot de passe</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Password" name="Password" value={this.state.utilisateur.Password} onChange={this.onChange.bind(this)} maxLength="8" required='required' />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="drprofile_id" className="col-sm-3 col-form-label">Profil eSeller Team</label>
										<div className="col-sm-6">
											<select value={this.state.utilisateur.drprofile_id ? this.state.utilisateur.drprofile_id : ''} onChange={this.onChange.bind(this)} id='drprofile_id' name="drprofile_id" className="form-control form-control-sm" required='required'>
												<option value='0'></option>
												{this.state.profils.map((profil, i) => {
													return <option key={i} value={profil.DRPROFILE_ID}>{profil.DESCR}</option>
												})}
											</select>
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="drprofile_id" className="col-sm-3 col-form-label">Profil eSeller Lead</label>
										<div className="col-sm-6">
											<select value={this.state.utilisateur.IDDRProfileEsellerLead ? this.state.utilisateur.IDDRProfileEsellerLead : ''} onChange={this.onChange.bind(this)} id='IDDRProfileEsellerLead' name="IDDRProfileEsellerLead" className="form-control form-control-sm">
												<option value='0'></option>
												{this.state.profilsLead.map((profil, i) => {
													return <option key={i} value={profil.IDDRProfileEsellerLead}>{profil.Libelle}</option>
												})}
											</select>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="drprofile_id" className="col-sm-3 col-form-label">Profil eDataVoice (PRC)</label>
										<div className="col-sm-6">
											<select value={this.state.utilisateur.IDProfil ?? ''} onChange={this.onChange.bind(this)} id='IDProfil' name="IDProfil" className="form-control form-control-sm"
											//TODO: Il faudrait que ce soit required si eDataVoice est activé mais pour l'instant on peut pas le davoir :(*/
											>
												<option value=''></option>
												{this.state.profilsEDataVoice.map((profil, i) => {
													return <option key={profil.IDProfil} value={profil.IDProfil}>{profil.Libelle}</option>
												})}
											</select>
										</div>
									</div>
								</div>

								<div className="col">

								</div>
							</div>

							<div className="row">
								{/* Options plutôt en rapport avec eSeller */}
								<div className="col-sm-6 col-md">

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="Actif" checked={this.state.utilisateur.Actif} onChange={this.onChange.bind(this)} />
													&nbsp; Compte activé
												</label>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="AccesCRMeSeller" checked={this.state.utilisateur.AccesCRMeSeller} onChange={this.onChange.bind(this)} />
													&nbsp; Accès CRMeSeller
												</label>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="Superviseur" checked={this.state.utilisateur.Superviseur} onChange={this.onChange.bind(this)} />
													&nbsp; Superviseur CRM
												</label>
											</div>
										</div>
									</div>
								</div>
								{/* Fin options plutôt en rapport avec eSeller */}

								<div className='col-sm-6 col-md'>
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="OffreEnTTC" checked={this.state.utilisateur.OffreEnTTC} onChange={this.onChange.bind(this)} />
													&nbsp; Offre en TTC
												</label>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="HorsTraitementLeads" checked={this.state.utilisateur.HorsTraitementLeads} onChange={this.onChange.bind(this)} />
													&nbsp; Hors traitement leads VN/VO
												</label>
												<i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
													title="Si cette option est activée l'utilisateur n'apparaitra plus dans les listes d'affectation de leads et ne recevra plus de notifications par email pour ce qui concerne les leads VN/VO/VDL (par exemple lors de la création d'un lead)">
												</i>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="HorsTraitementLeadsAPV" checked={this.state.utilisateur.HorsTraitementLeadsAPV} onChange={this.onChange.bind(this)} />
													&nbsp; Hors traitement leads APV
												</label>
												<i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
													title="Si cette option est activée l'utilisateur ne recevra plus de notifications par email pour ce qui concerne les leads APV (par exemple lors de la création d'un lead)">
												</i>
											</div>
										</div>
									</div>
								</div>

								{/* Types */}
								<div className="col-sm-6 col-md">
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="TypeVN" checked={this.state.utilisateur.TypeVN} onChange={this.onChange.bind(this)} />
													&nbsp; Type VN
												</label>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="TypeVO" checked={this.state.utilisateur.TypeVO} onChange={this.onChange.bind(this)} />
													&nbsp; Type VO
												</label>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="TypeVDL" checked={this.state.utilisateur.TypeVDL} onChange={this.onChange.bind(this)} />
													&nbsp; Type VDL
												</label>
												<i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
													title="Pris en compte uniquement dans eDataVoice">
												</i>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="TypeConseiller" checked={this.state.utilisateur.TypeConseiller} onChange={this.onChange.bind(this)} />
													&nbsp; Type Conseiller
												</label>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="TypeAutre" checked={this.state.utilisateur.TypeAutre} onChange={this.onChange.bind(this)} />
													&nbsp; Type Autre
												</label>
											</div>
										</div>
									</div>
								</div>

								{/* Options plutôt en rapport avec eSeller Lead */}
								<div className="col-sm-6 col-md">
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="AccesLeadVNVO" checked={this.state.utilisateur.AccesLeadVNVO} onChange={this.onChange.bind(this)} />
													&nbsp; Accès leads VN/VO
												</label>
											</div>
										</div>
									</div>

									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="AccesLeadAPV" checked={this.state.utilisateur.AccesLeadAPV} onChange={this.onChange.bind(this)} />
													&nbsp; Accès leads APV
												</label>
											</div>
										</div>
									</div>
								</div>

							</div>

							{this.props.match.params.id ?
								<div className="row">
									<div className="col">
										<h3>Sites</h3>

										<br />

										<div className='float-right'>
											<a className='btn btn-link' onClick={this.checkAllSite.bind(this)} tabIndex="1">Tout cocher</a> / <a className='btn btn-link' onClick={this.uncheckAllSite.bind(this)} tabIndex="2">Tout décocher</a>
										</div>

										<table className="table table-striped table-hover table-sm">
											<thead>
												<tr>
													<th>#</th>
													<th>Société</th>
													<th>Nom</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{this.state.sites.map((site) => {
													if (this.state.filterName) {
														if (site.Descr.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
															return false;
														}
													}

													return (<tr key={site.IDSite}>
														<td>{site.IDSite}</td>
														<td>{site.Plaque_Libelle}</td>
														<td>{site.Descr}</td>
														<td>
															<input type="checkbox" checked={site.checked} onChange={this.onChangeSite.bind(this, site.IDSite)} />
														</td>
													</tr>)
												})}
											</tbody>
										</table>

									</div>
								</div>
								: null}

							<br />

							{this.props.match.params.id && this.hasNissanSitesChecked() ?
								<div className="row">
									<div className="col">
										<h3>Identification nissan</h3>

										<table className="table table-striped table-hover table-sm">
											<thead>
												<tr>
													<th>Site</th>
													<th>Code vendeur</th>
													<th>Vectury ID</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{lodash.map(lodash.filter(this.state.sites, (site) => { return site.TPR_IDTypeProduit === 8 && site.checked; }), (site) => {
													const configNissan = this.state.utilisateur.ConfigNissan ? lodash.find(this.state.utilisateur.ConfigNissan, { 'IDSite': site.IDSite }) : null;
													return (
														<tr key={site.IDSite}>
															<td className="align-middle">{site.Plaque_Libelle} - {site.Descr}</td>
															<td>
																<input type="text" data-type="CodeVendeur" className="form-control form-control-sm w-auto" name={"CodeVendeur[" + site.IDSite + "]"} value={configNissan ? configNissan.CodeVendeur : ''} onChange={this.onChangeConfigNissan.bind(this, site.IDSite)} maxLength="8" />
															</td>
															<td>
																<input type="text" data-type="VecturyID" className="form-control form-control-sm w-auto" name={"VecturyID[" + site.IDSite + "]"} value={configNissan ? configNissan.VecturyID : ''} onChange={this.onChangeConfigNissan.bind(this, site.IDSite)} maxLength="8" />
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>

									</div>
								</div>
								: null}

						</div>
						<div className="tab-pane fade" id="renault" role="tabpanel" aria-labelledby="renault-tab">

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="codeIPN" className="col-sm-3 col-form-label">Numéro vendeur</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="CodeIPNRenault" name="CodeIPNRenault" value={this.state.utilisateur.CodeIPNRenault ? this.state.utilisateur.CodeIPNRenault : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>

								<div className="col">

								</div>
							</div>

							<div className="row">
								<div className="col">
									<h3>Renault CSB</h3>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="Username" className="col-sm-3 col-form-label">CSB Username</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Username" name="Username" value={this.state.configRenaultCSB.Username ? this.state.configRenaultCSB.Username : ''} onChange={this.onChangeRenaultCSB.bind(this)} />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="Password" className="col-sm-3 col-form-label">CSB Password</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Password" name="Password" value={this.state.configRenaultCSB.Password ? this.state.configRenaultCSB.Password : ''} onChange={this.onChangeRenaultCSB.bind(this)} />
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
					{this.buttonSave()}
				</form>


			</div>
		);
	}

	renderAccesClient() {
		return (
			<div className='container-fluid'>

				<h2>Utilisateurs</h2>
				<br />

				<ul className="nav nav-tabs" id="myTab">
					<li className="nav-item">
						<a className="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true">Général</a>
					</li>

					<li className="nav-item" key='sites-tab-li'>
						<a className="nav-link" id="renault-tab" data-toggle="tab" href="#renault" role="tab" aria-controls="renault" aria-selected="true">Renault</a>
					</li>
				</ul>
				<form onSubmit={this.handleSubmit.bind(this)}>
					<div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
						<div className="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">


							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="Lastname" className="col-sm-3 col-form-label">Nom</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Lastname" name="Lastname" value={this.state.utilisateur.Lastname} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="Firstname" className="col-sm-3 col-form-label">Prénom</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Firstname" name="Firstname" value={this.state.utilisateur.Firstname} onChange={this.onChange.bind(this)} maxLength="50" required='required' />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6">
									<div className="form-group row">
										<label htmlFor="Initial" className="col-sm-3 col-form-label">Initiale</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Initial" name="Initial" value={this.state.utilisateur.Initial ? this.state.utilisateur.Initial : ''} onChange={this.onChange.bind(this)} maxLength="3" required='required' />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="EMail" className="col-sm-3 col-form-label">Email</label>
										<div className="col-sm-6">
											<input type="email" className="form-control form-control-sm" id="EMail" name="EMail" value={this.state.utilisateur.EMail ? this.state.utilisateur.EMail : ''} onChange={this.onChange.bind(this)} maxLength="80" />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="PhoneNumber" className="col-sm-3 col-form-label">Téléphone</label>
										<div className="col-sm-6">
											<input type="tel" className="form-control form-control-sm" id="PhoneNumber" name="PhoneNumber" value={this.state.utilisateur.PhoneNumber ? this.state.utilisateur.PhoneNumber : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="MobilePhone" className="col-sm-3 col-form-label">Téléphone mobile</label>
										<div className="col-sm-6">
											<input type="tel" className="form-control form-control-sm" id="MobilePhone" name="MobilePhone" value={this.state.utilisateur.MobilePhone ? this.state.utilisateur.MobilePhone : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="PrivatePhone" className="col-sm-3 col-form-label">Téléphone privé</label>
										<div className="col-sm-6">
											<input type="tel" className="form-control form-control-sm" id="PrivatePhone" name="PrivatePhone" value={this.state.utilisateur.PrivatePhone ? this.state.utilisateur.PrivatePhone : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="LoginWEB" className="col-sm-3 col-form-label">Login</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="LoginWEB" name="LoginWEB" value={this.state.utilisateur.LoginWEB} onChange={this.onChange.bind(this)} maxLength="10" required='required' />
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">
										<label htmlFor="Password" className="col-sm-3 col-form-label">Mot de passe</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="Password" name="Password" value={this.state.utilisateur.Password} onChange={this.onChange.bind(this)} maxLength="8" required='required' />
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="drprofile_id" className="col-sm-3 col-form-label">Profil eSeller Team</label>
										<div className="col-sm-6">
											<select value={this.state.utilisateur.drprofile_id ? this.state.utilisateur.drprofile_id : ''} onChange={this.onChange.bind(this)} id='drprofile_id' name="drprofile_id" className="form-control form-control-sm" required='required'>
												<option value='0'></option>
												{this.state.profils.map((profil, i) => {
													return <option key={i} value={profil.DRPROFILE_ID}>{profil.DESCR}</option>
												})}
											</select>
										</div>
									</div>
								</div>

								<div className="col">
									<div className="form-group row">

									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="AccesCRMeSeller" checked={this.state.utilisateur.AccesCRMeSeller} onChange={this.onChange.bind(this)} disabled={this.state.utilisateur.IsAccountOpened} />
													&nbsp; Accès CRMeSeller
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="col">
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="Actif" checked={this.state.utilisateur.Actif} onChange={this.onChange.bind(this)} disabled={this.state.utilisateur.IsAccountOpened} />
													&nbsp; Compte activé
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col">
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="OffreEnTTC" checked={this.state.utilisateur.OffreEnTTC} onChange={this.onChange.bind(this)} />
													&nbsp; Offre en TTC
												</label>
											</div>
										</div>
									</div>
								</div>

								<div className="col">
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="HorsTraitementLeads" checked={this.state.utilisateur.HorsTraitementLeads} onChange={this.onChange.bind(this)} />
													&nbsp; Hors traitement leads VN/VO
												</label>
												<i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
													title="Si cette option est activée l'utilisateur n'apparaitra plus dans les listes d'affectation de leads et ne recevra plus de notifications par email pour ce qui concerne les leads VN/VO/VDL (par exemple lors de la création d'un lead)">
												</i>
											</div>
										</div>
									</div>
								</div>

								<div className="col">
									<div className="row">
										<div className="col-3"></div>
										<div className="col">
											<div className="form-check">
												<label className="form-check-label">
													<input className="form-check-input" type="checkbox" name="HorsTraitementLeadsAPV" checked={this.state.utilisateur.HorsTraitementLeadsAPV} onChange={this.onChange.bind(this)} />
													&nbsp; Hors traitement leads APV
												</label>
												<i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
													title="Si cette option est activée l'utilisateur ne recevra plus de notifications par email pour ce qui concerne les leads APV (par exemple lors de la création d'un lead)">
												</i>
											</div>
										</div>
									</div>
								</div>
							</div>

							{this.state.configESellerLead && this.state.configESellerLead.Actif && this.state.configESellerLead.IDClient ?
								<span>
									<hr />
									<div className="row">
										<div className="col">
											<h5>eSeller Lead</h5>
											<div className="form-group row">
												<label htmlFor="drprofile_id" className="col-sm-3 col-form-label">Profil eSeller Lead</label>
												<div className="col-sm-6">
													<select value={this.state.utilisateur.IDDRProfileEsellerLead ? this.state.utilisateur.IDDRProfileEsellerLead : ''} onChange={this.onChange.bind(this)} id='IDDRProfileEsellerLead' name="IDDRProfileEsellerLead" className="form-control form-control-sm">
														<option value='0'></option>
														{this.state.profilsLead.map((profil, i) => {
															return <option key={i} value={profil.IDDRProfileEsellerLead}>{profil.Libelle}</option>
														})}
													</select>
												</div>
											</div>
										</div>

										<div className="col"></div>
									</div>

									<div className="row">
										<div className="col">

											<div className="row">
												<div className="col-3"></div>
												<div className="col">
													<div className="form-check">
														<label className="form-check-label">
															<input className="form-check-input" type="checkbox" name="TypeVN" checked={this.state.utilisateur.TypeVN} onChange={this.onChange.bind(this)} />
															&nbsp; Type VN
														</label>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-3"></div>
												<div className="col">
													<div className="form-check">
														<label className="form-check-label">
															<input className="form-check-input" type="checkbox" name="TypeVO" checked={this.state.utilisateur.TypeVO} onChange={this.onChange.bind(this)} />
															&nbsp; Type VO
														</label>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-3"></div>
												<div className="col">
													<div className="form-check">
														<label className="form-check-label">
															<input className="form-check-input" type="checkbox" name="TypeVDL" checked={this.state.utilisateur.TypeVDL} onChange={this.onChange.bind(this)} />
															&nbsp; Type VDL
														</label>
														<i className="fa fa-question-circle ml-2" style={{ color: "#9A0059" }} data-toggle="tooltip" data-trigger="hover" data-placement="top" data-html="true"
															title="Pris en compte uniquement dans eDataVoice">
														</i>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-3"></div>
												<div className="col">
													<div className="form-check">
														<label className="form-check-label">
															<input className="form-check-input" type="checkbox" name="TypeConseiller" checked={this.state.utilisateur.TypeConseiller} onChange={this.onChange.bind(this)} />
															&nbsp; Type Conseiller
														</label>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-3"></div>
												<div className="col">
													<div className="form-check">
														<label className="form-check-label">
															<input className="form-check-input" type="checkbox" name="TypeAutre" checked={this.state.utilisateur.TypeAutre} onChange={this.onChange.bind(this)} />
															&nbsp; Type Autre
														</label>
													</div>
												</div>
											</div>
										</div>

										<div className="col">
											<div className="row">
												<div className="col-3"></div>
												<div className="col">
													<div className="form-check">
														<label className="form-check-label">
															<input className="form-check-input" type="checkbox" name="AccesLeadVNVO" checked={this.state.utilisateur.AccesLeadVNVO} onChange={this.onChange.bind(this)} />
															&nbsp; Accès leads VN/VO
														</label>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-3"></div>
												<div className="col">
													<div className="form-check">
														<label className="form-check-label">
															<input className="form-check-input" type="checkbox" name="AccesLeadAPV" checked={this.state.utilisateur.AccesLeadAPV} onChange={this.onChange.bind(this)} />
															&nbsp; Accès leads APV
														</label>
													</div>
												</div>
											</div>
										</div>
									</div>

								</span>
								: null}

							{this.props.match.params.id ?
								<div className="row">
									<div className="col">
										<h3>Sites</h3>

										<br />

										<div className='float-right'>
											<a className='btn btn-link' onClick={this.checkAllSite.bind(this)} tabIndex="1">Tout cocher</a> / <a className='btn btn-link' onClick={this.uncheckAllSite.bind(this)} tabIndex="2">Tout décocher</a>
										</div>

										<table className="table table-striped table-hover table-sm">
											<thead>
												<tr>
													<th>#</th>
													<th>Société</th>
													<th>Nom</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												{this.state.sites.map((site) => {
													if (this.state.filterName) {
														if (site.Descr.toLowerCase().search(this.state.filterName.toLowerCase()) === -1) {
															return false;
														}
													}

													return (<tr key={site.IDSite}>
														<td>{site.IDSite}</td>
														<td>{site.Plaque_Libelle}</td>
														<td>{site.Descr}</td>
														<td>
															{site.TPR_IDTypeProduit === 8 ? //Site Nissan ne peut pas être sélectionné ou déselectionné
																<input type="checkbox" checked={site.checked} disabled={true} />
																:
																<input type="checkbox" checked={site.checked} onChange={this.onChangeSite.bind(this, site.IDSite)} />
															}
														</td>
													</tr>)
												})}
											</tbody>
										</table>

									</div>
								</div>
								: null}

							<br />

						</div>
						<div className="tab-pane fade" id="renault" role="tabpanel" aria-labelledby="renault-tab">

							<div className="row">
								<div className="col">
									<div className="form-group row">
										<label htmlFor="codeIPN" className="col-sm-3 col-form-label">Numéro vendeur</label>
										<div className="col-sm-6">
											<input type="text" className="form-control form-control-sm" id="CodeIPNRenault" name="CodeIPNRenault" value={this.state.utilisateur.CodeIPNRenault ? this.state.utilisateur.CodeIPNRenault : ''} onChange={this.onChange.bind(this)} />
										</div>
									</div>
								</div>

								<div className="col">

								</div>
							</div>

						</div>
					</div>
					{this.buttonSave()}
				</form>
			</div>
		);
	}
}

export default Form;
