import React from "react"
import { useParams } from "react-router-dom"
import { CrmParam } from "../Types/CrmParam"
import { Site } from "../../../../types/Site"
import { SiteMarqueWally } from "../Types/Class/SiteMarqueWally"
import { SiteWally } from "../Types/Class/SiteWally"
import { titleTypes } from "../Types/TitleType"
import { MarqueWally } from "../Types/Class/MarqueWally"
import Mapping from "../MappingComponents/Mapping"

export default function Index() {

    const params:CrmParam = useParams<CrmParam>()

    const transformedDataSiteESeller = (data:any) => {
        return data.map((site:any) => new Site(site)) .sort((a:any,b:any) => {
            const nameA = a.Descr // ignore upper and lowercase
            const nameB = b.Descr // ignore upper and lowercase
            if (nameA < nameB) {
                return -1
            }
            if (nameA > nameB) {
                return 1
            }
            // names must be equal
            return 0
        })
    }

    const transformedDataSiteMarquesWally = (data:any,sites:any) => {
        return data
            .map((pdv:any) => new SiteMarqueWally(pdv, sites))
            .sort((a:any,b:any) => {
                let nameA = a.SiteDescr // ignore upper and lowercase
                let nameB = b.SiteDescr // ignore upper and lowercase


                if(nameA === undefined ) nameA = "zzzzzzzzz"
                if(nameB === undefined ) nameB = "zzzzzzzzz"
                if (nameA < nameB) {
                    return -1
                }
                if (nameA > nameB) {
                    return 1
                }
                // names must be equal
                return 0
            })
    }

    const transformedDataMarquesWally = (data:any) => {
        return data.map((mq:any) => new MarqueWally(mq))
    }

    const transformedDataSiteWally = (data:any) => {
        return data.map((site:any) => new SiteWally(site))
    }

    return <Mapping
        title={"Point de vente " + titleTypes[params.crm_name]}
        crm_title={titleTypes[params.crm_name]}
        crm_name={params.crm_name}
        backLink={"/interfaces/wally/"}
        toggleReload={true}
        toggleAddItem={false}

        // Account
        accounts={[]}
        accountSelected={null}
        loadAccount={true}

        // Wally Merged
        wallyMerged_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques-sites'}
        wallyMerged_urlToSaveData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques-sites'}
        wallyMerged_urlToRefreshData={(account_id: string) => '/interfaces/wally/accounts/' + account_id + '/marques-sites/refresh'}
        wallyMerged_transformedData={transformedDataSiteMarquesWally}
        wallyMerged_errorMsgFetch={"sites/marques"}

        // Wally
        wally_columnTitle={"Point de vente ["+ titleTypes[params.crm_name]+"]"}
        wally_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/sites'}
        wally_transformedData={transformedDataSiteWally}
        wally_errorMsgFetch={"sites"}
        wally_select_placeHolder={"le site"}


        // Marques
        marques_columnTitle={"Marques ["+ titleTypes[params.crm_name]+"]"}
        marques_urlToFetchData={(account_id:string) => '/interfaces/wally/accounts/' + account_id + '/marques'}
        marques_transformedData={transformedDataMarquesWally}
        marques_errorMsgFetch={"marques"}
        marques_select_placeHolder={"la marque"}


        // ESeller
        eSeller_urlToFetchData={'/interfaces/wally/sites'}
        eSeller_columnTitle={"Point de vente [ESeller]"}
        eSeller_errorMsgFetch={"sites"}
        eSeller_transformedData={transformedDataSiteESeller}
        eSeller_select_placeHolder={"le site ESeller"}
    />
}
