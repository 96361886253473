import { Link } from "react-router-dom"
import React from "react"
import HeaderPageWally from "./MappingComponents/HeaderPageWally"

function Index() {

    return <>
        <div className={"container-fluid mb-2"}>
            <div className={"row"}>
                <div className={"col"}>
                    <HeaderPageWally title={"Robot Wally"}/>

                    <div className="card my-4">
                        <div className="card-header" style={{ backgroundColor: "#ebedeb" }}>
                            Customer first
                        </div>
                        <ul className="list-group list-group-flush">
                            <Link to="/interfaces/wally/customer_first/comptes" className="list-group-item list-group-item-action">Compte</Link>
                            <Link to="/interfaces/wally/customer_first/point-de-vente" className="list-group-item list-group-item-action">Point de vente</Link>
                            <Link to="/interfaces/wally/customer_first/origines" className="list-group-item list-group-item-action">Origine</Link>
                            <Link to="/interfaces/wally/customer_first/logs" className="list-group-item list-group-item-action">Historique des intégrations</Link>
                        </ul>
                    </div>

                    <div className="card my-4">
                        <div className="card-header" style={{ backgroundColor: "#ebedeb" }}>
                            H-Way
                        </div>
                        <ul className="list-group list-group-flush">
                            <Link to="/interfaces/wally/hway/comptes" className="list-group-item list-group-item-action">Compte</Link>
                            <Link to="/interfaces/wally/hway/point-de-vente" className="list-group-item list-group-item-action">Point de vente</Link>
                            <Link to="/interfaces/wally/hway/origines" className="list-group-item list-group-item-action">Origine</Link>
                            <Link to="/interfaces/wally/hway/logs" className="list-group-item list-group-item-action">Historique des intégrations</Link>
                        </ul>
                    </div>

                    <div className="card my-4">
                        <div className="card-header" style={{ backgroundColor: "#ebedeb" }}>
                            My Sales
                        </div>
                        <ul className="list-group list-group-flush">
                            <Link to="/interfaces/wally/mysales/comptes" className="list-group-item list-group-item-action">Compte</Link>
                            <Link to="/interfaces/wally/mysales/point-de-vente" className="list-group-item list-group-item-action">Point de vente</Link>
                            <Link to="/interfaces/wally/mysales/origines" className="list-group-item list-group-item-action">Origine</Link>
                            <Link to="/interfaces/wally/mysales/logs" className="list-group-item list-group-item-action">Historique des intégrations</Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Index