import React, { useEffect, useState } from "react"
import { ButtonAction, ButtonAction2 } from "../../../UtilsComponents/ButtonAction"
import { useHistory, useParams } from "react-router-dom"
import { AccountParam } from "../Types/AccountParam"
import { titleTypes } from "../Types/TitleType"
import { CompteWally } from "../Types/Class/CompteWally"
import BackOfficeAPIClient from "../../../../clients/BackofficeAPIClient"
import HeaderPageWally from "../MappingComponents/HeaderPageWally"

export default function ActivationCompte() {
    const backofficeApiClient = new BackOfficeAPIClient()
    const params:AccountParam = useParams<AccountParam>()
    const history = useHistory();
    const [account,setAccount] = useState(new CompteWally());
    const [ errorEmail, setErrorEmail ] = useState<{color: string, message: string}>({ color: "inherit", message: "" })
    const [ isBtnDisabled, setBtnDisabled ] = useState(false)

    useEffect(() => {
        const lsAccount = localStorage.getItem("wally_account_"+params.account_id)
        if(lsAccount){
            setAccount(new CompteWally(JSON.parse(lsAccount)))
        }
    }, [])



    const handlePatchAccount = (state:string, flag?:string) => {
        //e.preventDefault()
        if (checkError() > 0) {
            return
        }
        setBtnDisabled(true)
        backofficeApiClient
            .patch<any>('/interfaces/wally/accounts/' + params.account_id,{state:state, flag:flag, emailNotification:account.EmailNotification})
            .then((r) => {
                history.push("/interfaces/wally/"+params.crm_name+"/comptes")
            }).catch(err => {
            console.error(err);
        }).finally(() => {
            setBtnDisabled(false)
        })
    }


    const checkError = () => {
        let errorCount = 0

        // Prerequis
        if (account.EmailNotification === "" || !account.EmailNotification) {
            setErrorEmail({ ...errorEmail, color: "lightcoral", message: "*Ce champ est obligatoire" })
            errorCount++
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex pour email
             if(!emailRegex.test(account.EmailNotification)){
                 errorCount ++;
                 setErrorEmail({ ...errorEmail, color: "lightcoral", message: "Veuillez saisir une adresse mail valide" })
             }
        }
        return errorCount
    }

    const handleChangeEmail = (e:any) => {
        setAccount({ ...account, EmailNotification: e.target.value })
        setErrorEmail({ ...errorEmail, color: "inherit", message: "" })
    }



    return <div className={"container-fluid mb-5 pr-5 pl-5"}>
        <div className={"row"}>
            <div className={"col"}>
                <HeaderPageWally title={"Activation du compte " + account.Identifiant + " pour " + titleTypes[params.crm_name]}></HeaderPageWally>
            </div>
        </div>
        <div className={"row mb-4"}>
            <div className={"col"}>

                <div className={"d-flex d-inline justify-content-between"}>
                    <ButtonAction href={"/interfaces/wally/" +
                        params.crm_name + "/comptes/creation/" + params.account_id + "/mapping-origines"} className={"btn btn-secondary"}>
                        <>
                            <i className={"fa-solid fa-chevron-left"}/>
                            <i className={"fa-solid fa-chevron-left"}/>
                        </>
                    </ButtonAction>

                    <div></div>
                </div>
            </div>

        </div>
        <div className={"d-flex d-inline justify-content-center w-100  mb-5"}>

            <div className={"w-50"}>
                <label htmlFor="email" className={"text-center w-100"}>
                    <h3>Associer à un email <span style={{ color: "red" }}>*</span></h3>
                    <small><b>Afin de vous prévenir en cas de mot de passse périmé ou de panne de compte, nous avons besoin d'une adresse email de référence pour ce compte</b></small>
                </label>
                <input type={"email"} className={"form-control form-control-xl p-2 pr-5"}
                       id={"email_notifications"} required onChange={handleChangeEmail}
                       name={"email"} value={account.EmailNotification}/>

                <small style={{ color: errorEmail.color }}>
                    <b>{errorEmail.message} </b>
                </small>
            </div>

        </div>
        <div className={"d-flex d-inline justify-content-around w-100 mb-5"}>

            <div className={"w-25"} style={{ display: "grid", placeItems: 'center' }}>
                <ButtonAction2 className={"btn btn-outline-primary btn-lg mb-2"}
                               disabled={isBtnDisabled}
                               onClick={() => handlePatchAccount("INACTIVE")}>
                    Laisser inactif
                </ButtonAction2>

            </div>

            <div className={"w-25"} style={{ display: "grid", placeItems: 'center' }}>
                <ButtonAction2 className={"btn btn-outline-success btn-lg mb-2"}
                               disabled={isBtnDisabled}
                               onClick={() => handlePatchAccount("ACTIVE")}>
                    Activer
                </ButtonAction2>
            </div>

        </div>
        <div className={"d-flex d-inline justify-content-around w-100"}>
            <div className={"w-25"} style={{ display: "grid", placeItems: 'center' }}>
                <p>
                    Si vous choisissez de laisser le compte inactif,
                    vous pourrez activer la récupération de données plus tard dans
                    l'interface de paramétrage des comptes.
                </p>
            </div>
            <div className={"w-25 align-content-start"} style={{ display: "grid", placeItems: 'center' }}>
                <p>
                    Si vous choisissez d'activer le compte, la récupération des données sera lancée immédiatement.
                </p>
            </div>

        </div>


    </div>
}
