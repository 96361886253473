import React, { useEffect, useState } from 'react'
import BackOfficeAPIClient from '../../../clients/BackofficeAPIClient'
import { useHistory } from "react-router-dom"
import { orderBy } from "lodash"

type Profil = {
    Libelle: string
    IDTypeProfil: number | null
}


type ProfilType = {
    IDTypeProfil: number
    Libelle: string
}
export default function EDataVoicePrcProfilCreate() {

    const backofficeApiClient = new BackOfficeAPIClient()

    const [ isFetching, setIsFetching ] = useState<boolean>(true)
    const [ error, setError ] = useState<string>()
    const [ profilTypes, setProfilTypes ] = useState<ProfilType[]>([])
    const [ profil, setProfil ] = useState<Profil>({Libelle:"",IDTypeProfil:null})

    const history = useHistory()

    useEffect(() => {
        fetchProfilTypes()
    }, [])

    const fetchProfilTypes = async () => {
        try {
            setIsFetching(true)
            setProfilTypes(orderBy(await backofficeApiClient.get<ProfilType[]>(`/profils/eDataVoicePrc/types`), "Libelle"))
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        } finally {
            setIsFetching(false)
        }
    }
    const handleSubmit = (event: any) => {
        event.preventDefault()
        createProfil()
    }

    const createProfil = async () => {
        try {
            setError("")
            await backofficeApiClient.post<Profil>(
                "/profils/eDataVoicePrc/create",
                profil,
            )
            history.push('/profils/eDataVoicePrc')
        } catch (error: any) {
            console.log(error)
            setError(error.message)
        }
    }

    return (
        <div className="container-fluid">
            <h2>Création Profil eDV (PRC)</h2>
            <br/>

            {error && <div className="alert alert-danger">
                <i className="fa fa-warning mr-1 mt-2"></i>
                {error}
            </div>}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col">
                        <div className="form-group row">
                            <label htmlFor="Nom" className="col-sm-1 col-form-label">Nom *</label>
                            <div className="col-sm-4">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="Descr"
                                    name="Descr"
                                    value={profil.Libelle}
                                    onChange={(e) => setProfil({ ...profil, Libelle: e.currentTarget.value })}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <div className="form-group row">
                            <label htmlFor="TypeProfil" className="col-sm-1 col-form-label">Type profil *</label>
                            <div className="col-sm-4">
                                <select
                                    value={profil.IDTypeProfil ?? ""}
                                    onChange={(e) => setProfil({ ...profil, IDTypeProfil: Number(e.currentTarget.value) })}
                                    id="TypeProfil"
                                    name="TypeProfil"
                                    className="form-control form-control-sm"
                                    required
                                >
                                    <option value={""}>Choisir un type de profil</option>
                                    {profilTypes.map((type, i) => {
                                        return <option key={type.IDTypeProfil} value={type.IDTypeProfil}>{type.Libelle}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: '15px' }}>
                    <div className="col text-center">
                        <button disabled={isFetching} type="submit" className="btn btn-primary">
                            {isFetching ?
                                <span><i className="fa fa-spinner fa-spin fa-fw"></i>&nbsp;</span>
                                : null}
                            Enregistrer
                        </button>
                    </div>
                </div>

            </form>
        </div>
    )
}